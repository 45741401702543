/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Layout, Hero2 } from 'components/shared'
import { Button, Heading } from 'components/elements'
import SEO from 'components/SEO'
import ParseContent from 'components/shared/ParseContent'
import ImageBackground from 'components/elements/ImageBackground'

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          title
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        intro {
          description
        }

        usp {
          title
          description
          button {
            title
            url
          }
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 300) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
            }
          }
        }

        project {
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          button {
            title
            url
          }
        }

        outro {
          description
          button {
            title
            url
          }
        }
      }

      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default function WorkMethod({
  data: {
    page: { path, yoastMeta, acf },
  },
}) {
  return (
    <Layout headerActive="Diensten">
      {yoastMeta && (
        <SEO
          yoast={{ meta: yoastMeta }}
          pathname={path}
          image={acf.banner.image.localFile.childImageSharp.fluid.src}
          article
        />
      )}

      <Hero2
        backgroundImg={acf.banner.image}
        title={acf.banner.title}
        aboveFold
      />
      <Intro description={acf.intro.description} items={acf.usp} />
      <Market {...acf.usp[0]} />
      <Hero2
        backgroundImg={acf.project.image}
        title={acf.project.description}
        button={acf.project.button}
      />
      <Market {...acf.usp[1]} />
      <Market {...acf.usp[2]} direction="left" />
      <Service fields={acf.outro} />
    </Layout>
  )
}

const IntroContainer = styled.div`
  width: 100%;
  background: ${(props) => props.theme.color.face.main};
  padding: 45px 0 40px 0;
  @media screen and (max-width: 1060px) {
    padding: 30px 0 27px 0;
  }
`
const IntroTitle = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    color: black;
    font-size: 25px;
    line-height: 30px;
    text-transform: uppercase;
    text-align: center;
    @media screen and (max-width: 1060px) {
      font-size: 17px;
      line-height: 20px;
    }
    @media screen and (max-width: 768px) {
      font-size: 15px;
      line-height: 18px;
      padding: 0 40px;
    }
  }
`
const WhiteLine = styled.div`
  width: 140px;
  background: ${(props) => props.theme.color.face.contrast};
  height: 3px;
  margin: 25px auto 40px auto;
  @media screen and (max-width: 1060px) {
    width: 100px;
    height: 2px;
    margin: 17px auto 27px auto;
  }
`
const IntroContent = styled.div`
  width: 1000px;
  display: flex;
  justify-content: space-around;
  margin: 0 auto 0 auto;
  @media screen and (max-width: 1060px) {
    width: 700px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    & > a {
      & > div {
        width: 200px;
      }
      margin: 0 auto;
    }
  }
`
const Intro = ({ description, items }) => {
  return (
    <IntroContainer>
      <IntroTitle content={description} />
      <WhiteLine />
      <IntroContent>
        {items.map((item) => {
          return (
            <Button key={item.title} label={item.title} to={item.button.url} />
          )
        })}
      </IntroContent>
    </IntroContainer>
  )
}

const MarketContent = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.direction === 'right' ? 'row' : 'row-reverse'};
  justify-content: space-between;
  margin: 40px auto 0px auto;
  @media screen and (max-width: 1060px) {
    margin: 30px auto 0px auto;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`

const MarketDescription = styled(ParseContent)`
  text-align: left;
  font-size: 16px;
  line-height: 30px;
  margin: 40px 0 50px 0;
  transition: all 300ms ease;
  @media screen and (max-width: 1060px) {
    font-size: 13px;
    line-height: 22px;
    margin: 28px 0 34px 0;
  }
`
const MarketImgShadow = styled.div`
  width: 360px;
  height: 360px;
  border: 8px solid #d8d8d8;
  position: relative;
  z-index: 0;
  transition: all 300ms ease;
  @media screen and (max-width: 1060px) {
    width: 250px;
    height: 250px;
    border: 6px solid #d8d8d8;
  }
  @media screen and (max-width: 768px) {
    width: 250px;
    height: 250px;
    border: 6px solid #d8d8d8;
    margin: 0 calc(50% - 140px);
  }
`
const MarketImg = styled(ImageBackground)`
  width: calc(100% + 16px);
  height: calc(100% + 16px);
  margin-left: 20px;
  margin-top: 20px;
  position: absolute;
  transition: all 300ms ease;
  z-index: 1;
  @media screen and (max-width: 1060px) {
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    margin-left: 16px;
    margin-top: 16px;
  }
`
const Market = ({ title, description, image, button, direction = 'right' }) => {
  return (
    <section className="py-5">
      <div className="container py-lg-5">
        <Heading text={title} />
        <MarketContent direction={direction}>
          <div className="col-md-6">
            <MarketDescription content={description} />
            <div>
              <Button
                label={button.title}
                to={button.url}
                shadowColor="#D8D8D8"
              />
            </div>
          </div>
          <div
            className={`col-md-6 d-flex mb-4 mb-md-0 ${
              direction === 'right'
                ? 'justify-content-end'
                : 'justify-content-start'
            }`}
          >
            <MarketImgShadow>
              <MarketImg src={image} />
            </MarketImgShadow>
          </div>
        </MarketContent>
      </div>
    </section>
  )
}

const ServiceContainer = styled.div`
  width: 100%;
  background: #d8d8d8;
  padding: 45px 0 80px 0;
  @media screen and (max-width: 1060px) {
    padding: 30px 0 56px 0;
  }
`

const Service = ({ fields }) => {
  return (
    <ServiceContainer>
      <IntroTitle content={fields.description} />
      <WhiteLine />
      <div className="d-flex justify-content-center">
        <Button label={fields.button.title} to={fields.button.url} />
      </div>
    </ServiceContainer>
  )
}
